import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  // #region Studies
  {
    path: '/study/:id/invite',
    name: 'StudyInvite',
    component: () => import('@/views/Studies/Main.vue'),
    meta: {
      title: 'Study Invite',
    },
  },
  // #region Studies
  {
    path: '/study/:id',
    name: 'Study',
    component: () => import('@/views/Studies/Main.vue'),
    meta: {
      title: 'Study',
    },
  },
  // #endregion Studies
  {
    path: '/session/:participantId/join',
    name: 'Interview',
    component: () => import('@/views/Studies/Interview/ConnectToZoom.vue'),
    meta: {
      title: 'Start a Session',
    },
  },
  {
    path: '/survey/:surveyId',
    name: 'SurveyPreview',
    component: () => import('@/views/Studies/Survey/Preview.vue'),
    meta: {
      title: 'Survey Preview',
    },
  },
  {
    path: '/survey/:surveyId/participant/:participantId',
    name: 'SurveyView',
    component: () => import('@/views/Studies/Survey/View.vue'),
    meta: {
      title: 'Survey View',
    },
  },
  {
    path: '/participant/:id',
    name: 'ParticipantProfile',
    component: () => import('@/views/Participant/Profile.vue'),
    meta: {
      title: 'Profile',
    },
  },

  {
    path: '/not-found',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue'),
    meta: {
      title: 'Page Not Found',
    },
  },
  {
    path: '/just-done',
    name: 'StudyJustDone',
    component: () => import('@/views/Studies/StudyJustDone.vue'),
    meta: {
      title: 'Study Just Done',
    },
  },
  
  // This catchall MUST be at the end of the routes
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'NotFound' },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.afterEach((to) => {
  // Sets document title as route meta->title
  document.title = import.meta.env.VITE_APP_NAME + (to.meta.title ? ' - ' + String(to.meta.title) : '')
})
// #endregion Navigation Guard

export default router
