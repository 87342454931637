import { reactive } from 'vue'
import { useLocalStorage, RemovableRef } from '@vueuse/core'

export class GlobalsService {
  public apiRequestInProgressCount = 0

  public showLoading = false

  private _participant = useLocalStorage('participant', {name: '', surname:'', email:'', studiesDone:[]} as any)//Contains the list of workspaces to which a user belongs
  public get participant(): any {
    return this._participant as unknown as any
  }
  public set participant(value: any ) {
    this._participant = value as unknown as RemovableRef<any>
  }
  /**
     * Increment API Request count and update showLoading state
     */
  incrementApiRequestInProgressCount(): void {
    this.apiRequestInProgressCount = this.apiRequestInProgressCount + 1
    this.showLoading = true
  }

  /**
     * Decrement API Request count and update showLoading state
     */
  decrementApiRequestInProgressCount(): void {
    this.apiRequestInProgressCount = this.apiRequestInProgressCount - 1
    if (this.apiRequestInProgressCount <= 0) {
      this.apiRequestInProgressCount = 0
      this.showLoading = false
    }
  }
}

const globalInstance = reactive(new GlobalsService()) as GlobalsService

export { globalInstance }
