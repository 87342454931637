<template>
  <v-footer
    class="footer bg-color1"
    app
    absolute
  >
    <v-card
      flat
      tile
      width="100%"
      class="text-center bg-color1 ma-0"
    >
      <v-divider/>
      <v-card-text class="footer mt-1">
        <span class="mr-4"><strong>&copy; {{ getYear }} {{ owner }}</strong></span>
        <span class="mr-4" @click="showVersion()"><strong>v{{ build }}</strong></span>
        <v-divider v-show="$vuetify.display.mdAndDown" class="transparent"/>
        <v-btn
          color="primary"
          variant="text"
          rounded="xl"
          :small="$vuetify.display.mdAndDown"
          @click="showPrivacy()"
        >
          Privacy Policy
        </v-btn>
        <v-btn
          color="primary"
          variant="text"
          rounded="xl"
          :small="$vuetify.display.mdAndDown"
          @click="showTerms()" 
        >
          Terms Of Service
        </v-btn>

        <v-btn
          color="primary"
          variant="text"
          rounded="xl"
          :small="$vuetify.display.mdAndDown"
          @click="showCookies()"
        >
          Cookie Policy
        </v-btn>
      </v-card-text>
    </v-card>

  </v-footer>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue'
import { ConduxService } from '@/services/condux.service'

// imports Dialogs and MessageBox
import { useMessageBoxStore, MessageConfig } from '@/store/message-box'

import pjson from '../../../package.json'

export default defineComponent({
  name: 'FooterBar',
  components: {
  },
  setup(): any {
    const conduxService = inject<ConduxService>('conduxService') as ConduxService

    const messageBox = useMessageBoxStore()   // declare the mb's store
    
    window.stores = { messageBox }         // initialize the store

    return {
      conduxService,
      messageBox,
    }
  },

  // #region Properties
  data(): any {
    return {
      backend_build: '',
      backend_types_version: '',
      version_max_clicks: 5,
      version_clicks: 0,
    }
  },
  computed: {
    getYear(): number {
      const year = (new Date()).getFullYear()

      if (year < 2022) {
        return 2022
      } else {
        return year
      }
    },
    owner(): string {
      return `${import.meta.env.VITE_APP_OWNER}`
    },
    build(): string {
      return `${import.meta.env.VITE_APP_BUILD}`
    },
    typesVersion(): string {
      // returns the current types version
      const v = pjson.dependencies['@conduxio/types']

      return v.replace('^', '')
    },
  },
  // #endregion Properties

  // #region Life Cycle
  created(): void {
    // gets backend build number and backend-types-version
    this.conduxService.getBackendInfo().then((response: any) => {
      if (response) {
        this.backend_build = response.backend
        this.backend_types_version = response.types
      }
    })
  },
  // #endregion Life Cycle

  methods:
  {
    showVersion(): void {
      this.version_clicks += 1
      if (this.version_clicks >= this.version_max_clicks) {

        const msg: MessageConfig = {
          title: 'Build Summary',
          message: 'Build informations in the below details',
          icon: 'mdi-link',
          details: [
            `Project: ${import.meta.env.VITE_APP_NAME}`,
            `Stage: ${import.meta.env.VITE_APP_ENV_NAME}`,
            `frontend build: ${this.build}`,
            `frontend types: ${this.typesVersion}`,
            `backend build: ${this.backend_build}`,
            `backend types: ${this.backend_types_version}`,
          ],
          showDetails: true,
          buttons: [
            {
              key: 'ok',
              text: 'Close',
              button: true,
            },
          ],
        }

        this.messageBox.show(msg)
      } else {
        // need more clicks
        /* eslint no-console: ["error", { allow: ["log"] }] */
        console.log(`You need to click ${this.version_max_clicks - this.version_clicks} times more, to display build summary`)
      }
    },
    showTerms(): void {
      window.open(import.meta.env.VITE_APP_URL_RESEARCHER + '/terms_of_service')
    },
    showPrivacy(): void {
      window.open(import.meta.env.VITE_APP_URL_RESEARCHER + '/privacy_policy')
    },
    showCookies(): void {
      window.open(import.meta.env.VITE_APP_URL_RESEARCHER + '/cookie_policy')
    },
  },

})
</script>

<style scoped>
.footer {
  font-size:.9em;
}
span {
  opacity: .5;
}
</style>
