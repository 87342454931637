<template>
  <v-app :theme="theme" class="b-medium_gray condux">
    <MainLayout v-if="!$route.query.preview" @dark-mode="toggleTheme">
      <router-view/>
    </MainLayout>
    <div v-else>
      <router-view/>
    </div>
    <!-- #region Components -->
    <message-box/>       <!-- MessageBox -->
    <notifications 
      position="bottom right" 
      :duration="10000" 
      :speed="1000" 
      :pause-on-hover="true" 
      :width="$vuetify.display.xs? '100%': '400px'"
      class="mb-3 pb-3"
    >
      <template #body="props">
        <v-card :data-cy="`notification-${props.item.type}`" class="my-notification" :class="props.item.type">
          <v-card-title>
            <v-icon v-if="props.item.type === 'info'" size="x-small" class="mr-1">{{ $icons.bell }}</v-icon>
            <span class="notification_title" v-html="props.item.title"></span>
            <v-spacer/>
            <button class="close" @click="closeNotification(props.item.id)">
              <v-icon size="x-small">{{ $icons.close }}</v-icon>
            </button>
          </v-card-title>
          <v-card-text>
            <span v-html="props.item.text"></span>
          </v-card-text>
        </v-card>
      </template>
    </notifications>
  </v-app>
</template>

<script lang="ts">
import { globalInstance } from './services/globals.service'
import { SessionService } from '@/services/session.service'
import { AIService } from '@/services/ai.service'
import { StudyService } from '@/services/study.service'
import { SurveyService } from '@/services/survey.service'
import { ConduxService } from '@/services/condux.service'
import { defineComponent, ref } from 'vue'
import MainLayout from '@/layouts/MainLayout.vue'

// Imports Components
import MessageBox from './components/notifications/message-box.vue'

export default defineComponent({
  name: 'App',
  components: {
    MainLayout,
    // Components
    MessageBox,
  },
  provide: {
    conduxService: ref(new ConduxService(globalInstance)),
    sessionService: ref(new SessionService(globalInstance)),
    studyService: ref(new StudyService(globalInstance)),
    surveyService: ref(new SurveyService(globalInstance)),
    aiService: ref(new AIService(globalInstance)),
  },
  setup() {
    // loads default/last-changed theme
    const theme = ref(localStorage.theme ?? 'light')

    return {
      theme,
      toggleTheme: (val: boolean): string => theme.value = val ? 'dark' : 'light',
    }
  },
  methods: {
    closeNotification(id: string) {
      this.$notify.close(id)
    },
  },
})
</script>

<style lang="scss" scoped>
.my-notification {
  margin-top: 5px;
  &.success {
    border-left: 5px solid green !important;
  }

  &.warn {
    border-left: 5px solid rgb(220, 220, 75);
  }

  &.error {
    border-left: 5px solid red;
  }
  &.info {
    border-left: 5px solid rgb(39, 39, 234);
  }

  span.notification_title {
    margin-top: -5px;
    display: inline-block;
  }
}
</style>