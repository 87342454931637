import { ConduxService } from '@/services/condux.service'
import {
  ConduxApiParticipantV1ObjectDetectionPostRequest,
  ConduxApiParticipantV1ObjectDetectionPostResponse, 
} from '@conduxio/types'

export class AIService extends ConduxService {

  protected modelNameSingular = 'ai'
  protected modelNamePlural = 'ai'

  public objectDetect(request: ConduxApiParticipantV1ObjectDetectionPostRequest): Promise<ConduxApiParticipantV1ObjectDetectionPostResponse> {
    const apiRequest = {url: this.modelNameSingular + '/object-detection/' , object: request }

    return super.post<ConduxApiParticipantV1ObjectDetectionPostResponse>(apiRequest)
  }

  public objectDetectTemp(request: ConduxApiParticipantV1ObjectDetectionPostRequest): Promise<ConduxApiParticipantV1ObjectDetectionPostResponse> {
    const ret = new Promise<ConduxApiParticipantV1ObjectDetectionPostResponse>((resolve) => {
      setTimeout(() => {
        const temp = {
          objects: [{
            name: 'Book',
            count: 1,
            coords: [{x: 54, y:46, width:22, height:8}],
          },
          {
            name: 'Mouse',
            count: 2,
            coords: [{x: 20, y:2, width:13, height:22},{x: 31, y:43, width:13, height:10}],
          }]
        } as ConduxApiParticipantV1ObjectDetectionPostResponse
        resolve(temp);
      }, 300);
    });
    console.log(request)
    return ret
  }
}
