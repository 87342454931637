import { defineStore } from "pinia";
/** 
  when using a store be sure to NOT destruct the store, otherwise it will loose its reactivity
  WRONG!!!
  const store = usePaginationStore()
  // this looses reactivity
  const { currentPage, TotalPages } = store

  CORRECT
  const store = usePaginationStore()
  store.currentPage 
  store.totalPages
  store.setCurrentPage(10)

  otherwisse if you really need to destruct the values use the storeToRef Methods instead 
  (just for the State, actions and other non reactive properties can be destructured directly)

  const store = usePaginationStore()
  const { currentPage, TotalPages } = storeToRef(store)
 * 
 */
//

export const usePageNavigationStore = defineStore("pageNavigation", {
  //state
  state: () => ({
    currentPage: 1,
    totalPages: 0,
    isSurvey: false,
  }),

  // reactive get
  getters: {
    percentageCompleted: (state) => {
      if (state.totalPages === 0) return 0;

      const pagePercentage = (state.currentPage / (state.totalPages - 1)) * 100;

      return pagePercentage;
    },
    shouldDisplay: (state) => {
      return state.isSurvey;
    },
  },

  // set & methods
  actions: {
    setTotalPages(totalPages: number) {
      this.totalPages = totalPages;
    },
    setCurrentPage(currentPage: number) {
      this.currentPage = currentPage;
    },
    setIsSurvey(isSurvey: boolean) {
      this.isSurvey = isSurvey;
    },
  },
});
