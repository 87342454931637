import { ConduxService } from '@/services/condux.service'
import {
  ConduxApiParticipantV1StudyAvailableTimestampsGetRequest,
  ConduxApiParticipantV1StudyAvailableTimestampsGetResponse,
  ConduxApiParticipantV1StudyAvailableTimestampsMonthGetRequest,
  ConduxApiParticipantV1StudyGetResponse,
} from '@conduxio/types'

export class StudyService extends ConduxService {

  protected modelNameSingular = 'study'
  protected modelNamePlural = 'studies'

  public read(id: string): Promise<ConduxApiParticipantV1StudyGetResponse> {
    const apiRequest = {url: this.modelNameSingular + '/' + id }

    return super.get<ConduxApiParticipantV1StudyGetResponse>(apiRequest)
  }

  public availableTimestamps(request: ConduxApiParticipantV1StudyAvailableTimestampsGetRequest): Promise<ConduxApiParticipantV1StudyAvailableTimestampsGetResponse> {
    const apiRequest = {url: this.modelNameSingular + '/available-timestamps', request: request, modelName: 'available timestamps'}

    return super.get<ConduxApiParticipantV1StudyAvailableTimestampsGetResponse>(apiRequest)
  }

  public availableMonths(request: ConduxApiParticipantV1StudyAvailableTimestampsMonthGetRequest): Promise<ConduxApiParticipantV1StudyAvailableTimestampsGetResponse> {
    const apiRequest = {url: this.modelNameSingular + '/available-timestamps-month', request: request, modelName: 'available months'}

    return super.get<ConduxApiParticipantV1StudyAvailableTimestampsGetResponse>(apiRequest)
  }
}
