<template>
  <div class="h-100" data-cy="body" :style="bgStyle">
    <div
      v-if="false"
      :class="$vuetify.display.smAndUp || 'mb-5'"
      style="display: flow-root"
    >
      <v-btn
        :title="`Switch to ${darkMode ? `light` : 'dark'} theme`"
        class="pa-0 float-right b-light_gray"
        flat
        small
        stacked
        @click="darkMode = !darkMode"
      >
        <v-icon color="primary"
          >mdi-{{ darkMode ? `white-balance-sunny` : `weather-night` }}</v-icon
        >
      </v-btn>
    </div>

    <v-row no-gutters class="mb-6 mt-2 pa-2">
      <!-- <v-col class="border"> -->
      <!-- <div class="mx-auto w-100 nav-bar--sticky">
        <v-progress-linear
          v-if="pageCounterStore.shouldDisplay ?? false"
          class="my-4 progress-bar--bordered"
          height="28"
          :model-value="pageCounterStore.percentageCompleted"
          max="100"
          :color="colorStore.getDarkerColor"
        />
      </div> -->
      <div
        class="mx-auto main-page w-100 container-border--highlight rounded-custom"
        :style="borderStyle"
      >
        <div class="b-light rounded-custom">
          <slot></slot>
        </div>
      </div>
      <!-- </v-col> -->
    </v-row>

    <FooterBar v-if="footerVisible" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FooterBar from "@/layouts/components/FooterBar.vue";
import { usePageNavigationStore } from "@/store/pageProgress";
import { useColorStore } from "@/store/colorStore";

export default defineComponent({
  name: "MainLayot",
  components: {
    FooterBar,
  },
  // #endregion Watchers

  emits: ["darkMode"],

  setup() {
    const pageCounterStore = usePageNavigationStore();
    const colorStore = useColorStore();

    return { pageCounterStore, colorStore };
  },
  // #region Properties
  data() {
    return {
      darkMode: false,
      bgColor: null,
    };
  },
  computed: {
    bgStyle() {
      return this.bgColor
        ? "background-color:" + this.colorStore.getDefaultColor + ";"
        : "";
      // return this.bgColor
      //   ? `background:linear-gradient(to bottom right, ${this.colorStore.getLigherColor} , ${this.colorStore.getDefaultColor});`
      //   : "";
    },
    borderStyle() {
      return "border: 2px solid" + this.colorStore.getDarkerColor;
    },
    footerVisible() {
      return (
        this.$route.name !== "SurveyPreview" &&
        this.$route.name !== "SurveyView"
      );
    },
  },

  watch: {
    darkMode(newValue) {
      localStorage.theme = newValue ? "dark" : "light";
      this.$emit("darkMode", newValue);
    },
    "$route.query": {
      immediate: true,
      handler(val) {
        if (val.bg) {
          this.bgColor = val.bg;
          this.colorStore.setHex(val.bg);
        }
      },
    },
  },
  // #endregion Properties

  // #region Life Cycle
  created() {
    // restores darkMode from localstorage or sets the default theme (light)
    this.darkMode =
      (localStorage.theme ?? this.$vuetify.theme.current) === "dark";
  },
  // #endregion Life Cycle

  methods: {},
});
</script>

<style lang="scss">
$footerheight: 100px;
@media (max-width: 1280px) {
  .v-main .v-main__wrap {
    min-height: calc(100vh - #{$footerheight} - 26px) !important;
  }
}
@media (max-width: 599px) {
  .v-main {
    margin-bottom: $footerheight;
  }
}
.v-main .v-main__wrap {
  min-height: calc(100vh - #{$footerheight} - 65px);
}
.main-page {
  max-width: 1100px;
  margin-bottom: 20px;
}
@media (max-width: 1280px) {
  .main-page {
    margin-bottom: 40px;
  }
}
@media (max-width: 524px) {
  .main-page {
    margin-bottom: 80px;
  }
}
@media (max-width: 350px) {
  .main-page {
    margin-bottom: 120px;
  }
}
</style>

<style scoped>
.container-border--highlight {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.rounded-custom {
  border-radius: 12px;
  /* overflow: hidden; */
}
</style>
