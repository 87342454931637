import { ConduxService } from '@/services/condux.service'
import {
  ConduxApiParticipantV1SurveyGetResponse,
} from '@conduxio/types'

export class SurveyService extends ConduxService {

  protected modelNameSingular = 'survey'
  protected modelNamePlural = 'surveys'

  public read(id: string): Promise<ConduxApiParticipantV1SurveyGetResponse> {
    const apiRequest = {url: this.modelNameSingular + '/' + id }

    return super.get<ConduxApiParticipantV1SurveyGetResponse>(apiRequest)
  }
}
