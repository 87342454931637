/* eslint-disable */
interface Blob {
  toBase64(maxSize? : number): Promise<string> 
}  
interface File {
  toBase64(maxSize? : number): Promise<string> 
}  
interface String {
  resizeBase64(size : number): Promise<any>
}  
// returns a base64 image format from file
Blob.prototype.toBase64 = async function (maxSize = undefined): Promise<string> {  
  if (this.type.match(/image.*/)) {
    const reader = new FileReader()

    reader.readAsDataURL(this)
    if(!maxSize)
      return await reader.result as string
    else
    {
      reader.onload = function (): any {
        const image = new Image()

        image.src = reader.result as string

        return image.onload = function (): any {
          // Resize the image
          const canvas = document.createElement('canvas')
          let { width } = image
          let { height } = image

          if (width > height) {
            if (width > maxSize) {
              height *= maxSize / width
              width = maxSize
            }
          } 
          else {
            if (height > maxSize) {
              width *= maxSize / height
              height = maxSize
            }
          }
          canvas.width = width
          canvas.height = height
          const context = canvas.getContext('2d') as any

          context.clearRect(0, 0, width, height)
          context.drawImage(image, 0, 0, width, height)
          let ret = canvas.toDataURL('image/jpeg') as string

          if (ret.length > 16000)
          {
            ret = canvas.toDataURL('image/jpeg', 16000 / ret.length) as string
            if (ret.length > 16000)
              throw new Error ('File too large, please upload a smaller image')
          }

          return ret               
        }
      }
    }
  }
  return ''
}
String.prototype.resizeBase64 = async function (size): Promise<any> {  
    const image = new Image()
    image.src = this.toString()

    return new Promise((resolve) => {
      image.onload = function (): void {
        // Loads the image
        const canvas = document.createElement('canvas')
        let newData = ''
        let { width } = image
        let { height } = image

        // checks if we have to resize it
        if (width > size || height > size) {
          // We have to resize the image
          if (width > height) {
            if (width > size) {
              height *= size / width
              width = size
            }
          } else {
            if (height > size) {
              width *= size / height
              height = size
            }
          }
          width = Math.floor(width)
          height = Math.floor(height)

          canvas.width = width
          canvas.height = height
          const context = canvas.getContext('2d') as any

          context.clearRect(0, 0, width, height)
          context.drawImage(image, 0, 0, width, height)
          newData = canvas.toDataURL('image/jpeg') as string
        } else {
          // We don't have to resize image
        }

        return resolve({
          data: newData,
          width: width,
          height: height,
        })
      }   // image.onload
    })      // return new Promise
}
File.prototype.toBase64 = async function (maxSize = undefined): Promise<string> {  
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(this);

    fileReader.onload = () => {
        resolve(fileReader.result as string);
    };

    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}