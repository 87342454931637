<template >
  <div>
    <div class="bar" :class="{'fixed': fixed}">
      <v-progress-linear
        v-if="$globalsService.showLoading"
        color="primary"
        indeterminate
      />
      <div v-else class="pt-1">

      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LoaderBase',
  props: {
    fixed: {
      type: Boolean,
    },
  },
})
</script>
<style lang="scss" scoped>
.bar
{ 
  width: 100%;
  
  &.fixed
  {
    position: fixed;
    left: 0;
    top: 60px;
    z-index: 10000;
  }
}
</style>
