/*
  ./src/static/icons
  defines app icons
  see:  https://materialdesignicons.com/
*/
export default {
  // #region Project Icons
  project: 'mdi-briefcase',
  projects: 'mdi-briefcase',
  project_add: 'mdi-briefcase-plus',
  project_edit: 'mdi-briefcase-edit',
  // #endregion Project Icons

  // #region Preview Icons
  preview_as_text: 'mdi-format-text-variant',
  preview_as_html: 'mdi-file-document-outline',
  // #endregion Preview Icons

  // #region Study Icons
  study: 'mdi-book',
  studies: 'mdi-book',
  study_add: 'mdi-book-plus',
  study_edit: 'mdi-book-edit',
  // #endregion Study Icons

  // #region Questionnaire Icons
  questionnaire: 'mdi-file-document-outline',
  questionnaires: 'mdi-file-document-multiple-outline',
  questionnaire_add: 'mdi-file-document-edit-outline',
  questionnaire_edit: 'mdi-file-document-edit-outline',
  // #endregion Questionnaire Icons

  // #region Questionnaire Icons
  user: 'mdi-account',
  users: 'mdi-account-supervisor',
  user_add: 'mdi-account-plus',
  user_edit: 'mdi-account-edit',
  // #endregion Questionnaire Icons

  // #region Zoom Icons
  zoom: 'mdi-monitor',
  zooms: 'mdi-monitor-multiple',
  zoom_add: 'mdi-monitor-arrow-down',
  zoom_edit: 'mdi-monitor-edit',
  // #endregion Zoom Icons

  // #region Calendar Icons
  calendar: 'mdi-calendar',
  calendars: 'mdi-calendar-blank-multiple',
  calendar_add: 'mdi-calendar-plus',
  calendar_edit: 'mdi-calendar-edit',
  // #endregion Calendar Icons

  // #region Scheduled Icons
  scheduled: 'mdi-timeline-clock-outline',
  scheduled_add: 'mdi-timeline-plus-outline',
  scheduled_edit: 'mdi-timeline-text-outline',
  // #endregion Scheduled Icons

  // #region CRUD icons
  loader: 'mdi-loading',
  reload: 'mdi-reload',
  add: 'mdi-plus',
  edit: 'mdi-pencil',
  view: 'mdi-eye',
  not_view: 'mdi-eye-off',
  delete: 'mdi-delete',
  // #endregion CRUD icons

  // #region Sessions icons
  group_completed: 'mdi-account-group',
  group_remaining: 'mdi-account-group-outline',
  schedule: 'mdi-calendar-clock',
  completed: 'mdi-check-circle-outline',
  missed: 'mdi-cancel',
  // #endregion Sessions icons

  // #region Galleries
  images: 'mdi-image-multiple',
  add_image: 'mdi-image-plus',
  // #endregion Galleries

  // #region Pages
  page: 'mdi-card-outline',
  page_add: 'mdi-card-plus-outline',
  // #endregion Pages

  // #region Generals icons
  dashboard: 'mdi-view-dashboard',
  home: 'mdi-home',
  info: 'mdi-information',
  stream: 'mdi-webcam',
  link: 'mdi-link',
  open_link: 'mdi-open-in-new',
  copy: 'mdi-content-copy',
  back: 'mdi-arrow-left-circle',
  account: 'mdi-account',
  logout: 'mdi-logout-variant',
  upload: 'mdi-upload',
  download: 'mdi-download',
  filter: 'mdi-filter',
  filters: 'mdi-filter',
  filters_off: 'mdi-filter-off',
  close: 'mdi-close',
  unlock: 'mdi-lock-open-outline',
  lock: 'mdi-lock-outline',
  timeZone: 'mdi-map-clock',
  help: 'mdi-help-circle',
  clock: 'mdi-clock',
  email: 'mdi-email',
  microsoft: 'mdi-microsoft',
  google: 'mdi-google',
  searh: 'mdi-magnify',
  menu: 'mdi-menu',
  sun: 'mdi-white-balance-sunny',
  moon: 'mdi-weather-night',
  bell: 'mdi-bell',
  vertical_dots: 'mdi-dots-vertical',
  horizontal_dots: 'mdi-dots-horizontal',
  video: 'mdi-video',
  sort: 'mdi-sort',
  sort_up: 'mdi-sort-descending',
  sort_down: 'mdi-sort-ascending',
  palette: 'mdi-palette',
  clock7: 'mdi-clock-time-seven-outline',
  settings: 'mdi-cog-outline',
  preview: 'mdi-file-find',
  expired: 'mdi-calendar-remove',
  password: 'mdi-key',
  old_password: 'mdi-key-minus',
  new_password: 'mdi-key-plus',
  camera: 'mdi-camera',
  drag: 'mdi-drag',
  drag_horizontal: 'mdi-drag-horizontal',
  true: 'mdi-check',
  addLIst: 'mdi-table-column-plus-after',
  color: 'mdi-invert-colors',
  chat: 'mdi-chat-question-outline',
  undo: 'mdi-file-undo-outline',
  // #endregion Generals icons
  // #region Controls
  checkbox: 'mdi-checkbox-blank-outline',
  checkbox_selected: 'mdi-checkbox-marked',
  radio: 'mdi-checkbox-blank-circle-outline',
  radio_selected: 'mdi-checkbox-marked-circle',
  select: 'mdi-menu-open',
  select_selected: 'mdi-arrow-down-drop-circle',
  // #endregion
}
