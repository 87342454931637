import { ConduxService } from '@/services/condux.service'
import {
  ConduxApiCommonV1Response,
  ConduxApiParticipantV1SessionGetResponse,
  ConduxApiParticipantV1SessionPostBody, 
  SessionParticipant,
  SessionHelper,
} from '@conduxio/types'

export class SessionService extends ConduxService {

  protected modelNameSingular = 'session'
  protected modelNamePlural = 'sessions'

  public read(id: string): Promise<ConduxApiParticipantV1SessionGetResponse> {
    const apiRequest = {url: this.modelNameSingular + '/' + id }

    return super.get(apiRequest)
  }

  public create(model: ConduxApiParticipantV1SessionPostBody, sessionType: 'survey' | 'zoom'): Promise<ConduxApiCommonV1Response> {
    const validationResult = SessionHelper.validateParticipant(model.participant, sessionType)

    if (!validationResult.valid) {
      return new Promise<ConduxApiCommonV1Response>((resolve) => {
        resolve({
          status: 'ERROR',
          errorMessage: 'Error creating Session, ' + validationResult.invalidReason,
        } as ConduxApiCommonV1Response)
      })
    }

    model.participant = validationResult.sessionParticipant as SessionParticipant

    const apiRequest = {url: this.modelNameSingular, object: model }

    return super.post<ConduxApiCommonV1Response>(apiRequest)
  }
}
