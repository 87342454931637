import icons from '@/static/icons'

export class Utilities {
  // #region Generic Functions
  public clone(obj: any): any {
    return { ...obj }
  }

  public equals(obj1: any, obj2: any): any {
    return JSON.stringify(obj1) === JSON.stringify(obj2)
  }

  public getSortIcon(property: any, orderName: string): string {
    if (property.orderByName !== orderName) {
      return icons.sort
    } else {
      if (property.orderByDirection === 'ASC') {
        return icons.sort_up
      } else {
        return icons.sort_down
      }
    }
  }

  public getBackgroundStyle(color: string): string {
    let css = 'background-color:' + color + ';'

    if (this.colorIsLight(color)) {
      css += 'color:black;'
    } else {
      css += 'color:white;'
    }

    return css
  }

  public colorIsLight(color: string, theshold = 160): boolean {
    const hex = color.replace('#', '')
    const r = parseInt(hex.substr(0, 2), 16)
    const g = parseInt(hex.substr(2, 2), 16)
    const b = parseInt(hex.substr(4, 2), 16)
    const hsp = Math.sqrt(
      0.299 * (r * r) +
            0.587 * (g * g) +
            0.114 * (b * b),
    )

    // const brightness = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    // return brightness > 150;
    return hsp > theshold
  }
    
  // #endregion Generic Functions
  public checkOperator(operator: 'AND' | 'OR' | undefined, params: boolean[]): boolean {
    let isValid = true

    switch (operator) {
      case 'AND':
        isValid = true
        params.forEach((param) => {
          isValid = param && isValid
        })
        break
      case 'OR':
        isValid = false
        params.forEach((param) => {
          isValid = param || isValid
        })
        break
    }

    return isValid
  }
}
