import * as Sentry from "@sentry/vue";
import router from '../router'

// Types
import type { App } from 'vue'

export function registerSentry(app: App): void {
  Sentry.init({
    app,
    dsn: "https://c5660a440e3348d0c08f28211d6fede9@o4506184290861056.ingest.sentry.io/4506184293679104",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled import.meta.env.VITE_APP_ROOT_API
        tracePropagationTargets: ['localhost'],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

    // eslint-disable-next-line no-unused-vars
    beforeSend(event, hint) {
      return event
    },
  })
}
