import { defineStore } from "pinia";

export const useColorStore = defineStore("color", {
  state: () => ({
    hex: "",
    darkerHex: "",
    lighterHex: "",
  }),

  getters: {
    getHslValue: (state) => {
      return state;
    },
    getDefaultColor: (state) => {
      return state.hex;
    },
    getDarkerColor: (state) => {
      return state.darkerHex;
    },
    getLigherColor: (state) => {
      return state.darkerHex;
    },
  },

  actions: {
    setHex(hexValue: string) {
      // Sets the hex value and updates the colors
      this.hex = hexValue;
      this.updateColors();
    },

    updateColors() {
      // Update darker and lighter colors based on the current hex value
      this.darkerHex = this.adjustColorLightness(this.hex, -0.3);
      this.lighterHex = this.adjustColorLightness(this.hex, 0.3);
    },

    adjustColorLightness(hex: string, adjustment: number) {
      const HSL = this.hexToHSL(hex);

      // Return original hex if conversion fails
      if (!HSL) return hex;

      // Adjust lightness within bounds
      HSL.l = Math.max(0, Math.min(100, HSL.l + HSL.l * adjustment));
      return this.hslToHex(HSL.h, HSL.s, HSL.l);
    },

    hexToHSL(hex: string) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      if (!result) return null;

      const [r, g, b] = result.slice(1).map((n) => parseInt(n, 16) / 255);
      const max = Math.max(r, g, b),
        min = Math.min(r, g, b);
      /**
       * h = hue
       * s = saturation
       * l = lightness
       */
      let h, s;

      // made lightness a constant for now
      const l = (max + min) / 2;

      if (max === min) {
        // achromatic
        h = s = 0;
      } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
          case r:
            h = (g - b) / d + (g < b ? 6 : 0);
            break;
          case g:
            h = (b - r) / d + 2;
            break;
          case b:
            h = (r - g) / d + 4;
            break;
          // default case needed otherwise TypeScript complains about a possible undefined value
          default:
            h = 0;
        }
        h /= 6;
      }

      return {
        h: Math.round(h * 360),
        s: Math.round(s * 100),
        l: Math.round(l * 100),
      };
    },

    hslToHex(h: number, s: number, l: number) {
      l /= 100;
      const a = (s * Math.min(l, 1 - l)) / 100;
      const f = (n: number): string => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color)
          .toString(16)
          .padStart(2, "0");
      };
      return `#${f(0)}${f(8)}${f(4)}`;
    },
  },
});
