<template >
  <div class="mt-n3 mb-n2">
    <v-breadcrumbs
      :items="breadcrumbItems"
      divider=">"
      class="bre"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BreadcrumbsBase',
  props: {
    items: 
     {
       type: Array<any>,
       required: true
     },
  },
  computed: {
    breadcrumbItems(): Array<any> {
      if (!this.$vuetify.display.mobile || this.$props.items.length === 1) {
        return this.$props.items
      } else {
        const { to } = this.$props.items[this.$props.items.length - 2]
        
        return [{
          title: '< back',
          disabled: false,
          to: to,
        }]
      } 
      
    },
  },
})
</script>

<style>
.v-breadcrumbs-item
{
  font-size: 20px;
  opacity:0.6
}
.v-breadcrumbs-item--disabled
{
  opacity:1!important;
}
.v-breadcrumbs-item--link:hover {
  text-decoration: none;
}
</style>
