import { ConduxApiCommonV1Response } from '@conduxio/types'
import axios, { AxiosResponse } from 'axios'
import { GlobalsService } from './globals.service'
import * as Sentry from '@sentry/vue';

export class ConduxService {
  globalsService: GlobalsService
  protected urlPathPrefix = '/participant/v1/'
  protected modelNameSingular: string; //e.g. study
  protected modelNamePlural: string; // e.g. studies

  public constructor(globalsService: GlobalsService) {
    this.modelNameSingular = ''
    this.modelNamePlural = ''
    this.globalsService = globalsService 
  }

  protected async get<T>(apiRequest: apiRequest): Promise<T> {
    this.globalsService.incrementApiRequestInProgressCount()

    // Sets request url and params from the apiRequest
    const requestUrl = import.meta.env.VITE_APP_ROOT_API + this.urlPathPrefix + apiRequest.url
    const params: object = this.getQueryParameters(apiRequest)

    return axios.get(requestUrl, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: false,
      params: params,
      paramsSerializer: {
        indexes: true, // use brackets with indexes
      } 
    }).then((response: AxiosResponse<T>) => {
      return response.data
    },
    ).catch((error: any) => {
      let message = ''
      if(error && error.response && error.response.data && error.response.data.errorMessage)
      {
        message = error.response.data.errorMessage
      }
      else
      {
        message = apiRequest.modelName? 'Error retrieving ' + apiRequest.modelName : 'Error retrieving ' + this.modelNameSingular
      }
      return this.catchManager<T>(message, requestUrl, 'GET')
    }
    ).finally(() => {
      this.globalsService.decrementApiRequestInProgressCount()
    })
  }

  protected async post<T>(apiRequest: apiRequest): Promise<T> {
    this.globalsService.incrementApiRequestInProgressCount()

    // Sets request url and params from the apiRequest
    const requestUrl = import.meta.env.VITE_APP_ROOT_API + this.urlPathPrefix + apiRequest.url
    const params: object = this.getQueryParameters(apiRequest)

    return axios.post(requestUrl, apiRequest.object, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: false,
      params: params
    }).then((response: AxiosResponse<T>) => {    
      return response.data
    },
    ).catch((error: any) => {
      let message = ''
      if(error && error.response && error.response.data && error.response.data.errorMessage)
      {
        message = error.response.data.errorMessage
      }
      else
      {
        message = apiRequest.modelName? 'Error creating ' + apiRequest.modelName : 'Error creating ' + this.modelNameSingular
      }
      return this.catchManager<T>(message, import.meta.env.VITE_APP_ROOT_API + this.urlPathPrefix + this.modelNameSingular, 'POST')
    }
    ).finally(() => {
      this.globalsService.decrementApiRequestInProgressCount()
    })
  }

  protected async put<T>(apiRequest: apiRequest): Promise<T> {
    if (!apiRequest.modelId) {
      return new Promise<T>((resolve) => {
        resolve({
          status: 'ERROR',
          errorMessage: 'Error updating ' + this.modelNameSingular + '. Error [' + this.modelNameSingular + ' id is missing or empty]',
        } as unknown as T)
      })
    }
    this.globalsService.incrementApiRequestInProgressCount()

    // Sets request url and params from the apiRequest
    const requestUrl = import.meta.env.VITE_APP_ROOT_API + this.urlPathPrefix + apiRequest.url
    const params: object = this.getQueryParameters(apiRequest)

    return axios.put(requestUrl, apiRequest.object, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: false,
      params: params,
    }).then((response: AxiosResponse<T>) => {    
      return response.data
    },
    ).catch((error: any) => {
      let message = ''
      if(error && error.response && error.response.data && error.response.data.errorMessage)
      {
        message = error.response.data.errorMessage
      }
      else
      {
        message = apiRequest.modelName? 'Error updating ' + apiRequest.modelName : 'Error updating ' + this.modelNameSingular
      }
      return this.catchManager<T>(message, import.meta.env.VITE_APP_ROOT_API + this.urlPathPrefix + this.modelNameSingular, 'PUT')
    }
    ).finally(() => {
      this.globalsService.decrementApiRequestInProgressCount()
    })
  }

  protected async delete<T>(apiRequest: apiRequest): Promise<T> {
    if (!apiRequest.modelId) {
      return new Promise<T>((resolve) => {
        resolve({
          status: 'ERROR',
          errorMessage: 'Error deleting ' + this.modelNameSingular + '. Error [' + this.modelNameSingular + ' id is missing or empty]',
        } as unknown as T)
      })
    }
    this.globalsService.incrementApiRequestInProgressCount()
    // Sets request url and params from the apiRequest
    const requestUrl = import.meta.env.VITE_APP_ROOT_API + this.urlPathPrefix + apiRequest.url
    const params: object = this.getQueryParameters(apiRequest)

    return axios.delete(requestUrl, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: false,
      params: params,
    }).then((response: AxiosResponse<T>) => {    
      return response.data
    },
    ).catch((error: any) => {
      let message = ''
      if(error && error.response && error.response.data && error.response.data.errorMessage)
      {
        message = error.response.data.errorMessage
      }
      else
      {
        message = apiRequest.modelName? 'Error deleting ' + apiRequest.modelName : 'Error deleting ' + this.modelNameSingular
      }
      return this.catchManager<T>(message, import.meta.env.VITE_APP_ROOT_API + this.urlPathPrefix + this.modelNameSingular, 'DELETE')
    }
    ).finally(() => {
      this.globalsService.decrementApiRequestInProgressCount()
    })
  }

  protected getBackendInfo(): Promise<ConduxApiCommonV1Response> { // eslint-disable-line no-unused-vars
    this.globalsService.incrementApiRequestInProgressCount()

    return axios.get(import.meta.env.VITE_APP_ROOT_API + '/info/v1/version', {
      headers: {
        'Content-Type': 'application/json',
      } }
    ).then((response: AxiosResponse<ConduxApiCommonV1Response>) => {
      return response.data
    },
    ).catch((error: any) => {
      let message = ''
      if(error && error.response && error.response.data && error.response.data.errorMessage)
      {
        message = error.response.data.errorMessage
      }
      else
      {
        message = 'Error retrieving backend info version'
      }
      return this.catchManager<ConduxApiCommonV1Response>(message, import.meta.env.VITE_APP_ROOT_API + '/info/v1/version', 'GET')
    }
    ).finally(() => {
      this.globalsService.decrementApiRequestInProgressCount()
    })
  }

  protected catchManager<T>(error: string, api: string, apiMethod: string): T
  {
    if (this.globalsService.participant && this.globalsService.participant.email)
    {
      Sentry.setUser({ email: this.globalsService.participant.email })
    } 
    Sentry.setTag('platform', 'participant')
    Sentry.setTag('api', api)
    Sentry.setTag('api-type', apiMethod)
    Sentry.captureException(error)
    return {
      status: 'ERROR',
      errorMessage: error,
    } as unknown as T
  }

  protected getQueryParameters(apiRequest: apiRequest): object {
    let params = {} as any
    if (apiRequest.request) {
      params = { ...apiRequest.request } 
    }

    if (apiRequest.include && apiRequest.include !== '') 
      params.include = apiRequest.include // appends the "include" param if given and not empty
   
    return params
  }
}

class apiRequest {
  public url = null as string | null
  public request? = null as any
  public modelName? = null as string | null
  public object? = null as Object | null
  public modelId? = null as string | null
  public include? = null as string | null
}
