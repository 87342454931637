import { SurveyQuestion } from '@/static/types/survey-result'
export default {
  required: (v: string): boolean | string => {
    return !!v || 'Please, answer this question'
  },
  required_radio(values: Array<any>): boolean | string
  {
    return (values && values.length > 0) || 'Please, answer this question'
  },
  required_checkbox(question: SurveyQuestion): boolean | string
  {
    if (question.answer && question.answer.codes && question.answer.codes.length > 0)
    {
      return true
    }
    return 'Please, answer this question'
  },
  required_value(value: string): boolean | string
  {
    return (!!value && value.length > 0) || 'Please, answer this question'
  },
  answer_required: (v: string): boolean | string => {
    return !!v || 'Please, answer this question'
  },
  answer_required_file: (v: string): boolean | string => {
    return v.length > 0 || 'Please, answer this question'
  },
  email: (v: string): boolean | string => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return !v || pattern.test(v) || 'Invalid e-mail'
  },
  url: (v: string): boolean | string => {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator

    return !v || pattern.test(v) || 'Invalid url'
  },
  min(num: number) {
    return (v: string): boolean | string => (v || '').length >= num || `Min ${num} characters`
  },
  minWithParam(v: string, num: number): boolean | string {
    return (v || '').length >= num || `Min ${num} characters`
  },
  max(num: number) {
    return (v: string): boolean | string => (v || '').length <= num || `Max ${num} characters`
  },
  upper: (v: string): boolean | string => {
    const pattern = /[A-Z]{1}/

    return pattern.test(v) || 'At least one uppercase character'
  },
  lower: (v: string): boolean | string => {
    const pattern = /[a-z]{1}/

    return pattern.test(v) || 'At least one lowercase character'
  },
  digit: (v: string): boolean | string => {
    const pattern = /\d/

    return pattern.test(v) || 'At least one digit'
  },
  special: (v: string): boolean | string => {
    const pattern = /[^A-Za-z0-9]/

    return pattern.test(v) || 'At least one special character'
  },
  match(field: string) {
    return (v: string): boolean | string => v === field || 'Password does not match'
  },
  dateMajor(start: string, end: string): boolean | string {
    const DateStart = new Date(start)
    const DateEnd = new Date(end)

    return ((!start || !end || DateEnd > DateStart)) || 'Date must be greater then ' + DateStart.toLocaleDateString()
  },
}
