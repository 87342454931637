/* eslint-disable */
interface Number {
  timestampToLongDate(): string
  timestampToDate(): string
  timestampToDateString(): string
  timestampToTime(format: string, short?: boolean): string
  secondsToTime(format: string, short?: boolean): string
  numberToTime(): string
}  
interface String {
  dateToDateString(): string
  timeToArray(): Array<number>
  timeToNumber(): number
}  
// returns an ISO date-time from timestamp (ex: 1654725599000 => 2022-06-08T21:59:59.000Z)
Number.prototype.timestampToLongDate = function (): string {  
  if (this === undefined) return ''
  return new Date(Number(this)).toISOString()
}
// returns date from timestamp (ex: 1654725599000 => 2022-06-08)
Number.prototype.timestampToDate = function (): string {  
  if (this === undefined) return ''
  const p = (new Date(Number(this)).toISOString()).split('T')

  return p[0]
}
// returns date string from timestamp (ex: 1654725599000 => Wed Jun 08 2022)
Number.prototype.timestampToDateString = function (): string {  
  if (this === undefined) return ''
  const date = new Date(Number(this))
  
  return date.toDateString()
}
// returns date string from date (ex: 2023-03-15 => Wed Mar 15 2023)
String.prototype.dateToDateString = function (): string {  
  if (this === undefined) return ''
  const date = new Date(this as string)

  return date.toDateString()
}
// returns time format from timestamp (ex: 1654725599000 => 21:59:59)
Number.prototype.timestampToTime = function (format: 'hh:mm:ss' | 'hh:mm', short = false): string {  
  if (this === undefined) return ''
  let time = ''
  if(format === 'hh:mm:ss')
    time = new Date(Number(this)).toISOString().substring(11, 19)
  else
    time = new Date(Number(this)).toISOString().substring(11, 16)
  if (short) {    // removes 00 hours
    if (time.startsWith('00:')) time = time.substring(3)
  }
  return time
}

// returns time format from seconds (ex: 1120 => 00:18:40)
Number.prototype.secondsToTime = function (format: 'hh:mm:ss' | 'hh:mm', short = false): string { 
  const temp = Number(this) * 1000 
  return temp.timestampToTime(format, short)
}

// converts a time '01:07' into an array of numbers [1,7]
String.prototype.timeToArray = function (): Array<number> { 
  const arr = this.split(':')

  return arr.map((timePart) => {
    return parseInt(timePart)
  })
}
// converts a time '01:07' to number 10.07
String.prototype.timeToNumber = function (): number { 
  const arr = this.split(':')
  const dec = parseInt(arr[1])

  return parseFloat(parseInt(arr[0]) + '.' + (dec < 10 ? '0' : '') + dec)
}
// converts a number 10.07 to time '01:07'
Number.prototype.numberToTime = function (): string { 
    const time = parseFloat(this.toFixed(2))

    const hour = Math.floor(time)
    const minute = ((time - hour) * 100).toFixed(0)

    return hour.toString().padStart(2, '0') + ':' + minute.toString().padStart(2, '0')
}
