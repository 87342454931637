<!--
  imageUploader

  USAGE:
    <image-uploader
      width="240px"                                         // size in px o %
      height="240px"
      mode="input"                                          // mode: onlyDragAndDrop|onlyInput|all
      title="Trascina qui la tua immagine"                  // initial title
      drag-title="Rilascia la tua immagine"                 // title while dragging file
      upload-title="Uploading..."                           // title while uploading file
      @onUpload="onImageUpload($event)"                     // image selected
      @onUnexcpectedFormat="onUnexcpectedFormat($event)"    // wrong file format selected
    ></image-uploader>

    onImageUpload(file) {
    },
    onUnexcpectedFormat(fileType) {
    }
-->
<template>
  <div
    class="dragContainer"
    :class="border ? `` : `no-border`"
    @dragover.prevent="onDragOver()" 
    @dragleave.prevent="onDragLeave()"
    @drop.prevent="onDrop($event)"
  >
    <input
      v-if="allowInput"
      ref="image_uploader_input"
      type="file"
      accept="image/*"
      class="input-file"
      @change="onSelectFile($event.target)"
    />
    <b>{{ statusTitle }}</b>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'FileUploader',
  components: {
  },

  props: {
    width: {
      type: String,
      required: false,
      default() {
        return '100%'
      },
    },
    height: {
      type: String,
      required: false,
      default() {
        return '100%'
      },
    },
    title: {
      type: String,
      required: false,
      default() {
        return 'Drag & Drop here your image'
      },
    },
    mode: {
      type: String,
      required: false,
      default() {
        return 'all'              // onlyDragAndDrop|onlyInput|all
      },
    },
    dragTitle: {
      type: String,
      required: false,
      default() {
        return 'Leave here your image'
      },
    },
    uploadTitle: {
      type: String,
      required: false,
      default() {
        return 'Uploading...'
      },
    },
    border: {
      type: Boolean,
      required: false,
      default() {
        return true
      },
    },
  },

  emits: ['onResize', 'onUpload', 'onUnexcpectedFormat'],
  setup() {
    // refs
    const image_uploader_input: any = ref('image_uploader_input')

    return {
      image_uploader_input,
    }
  },

  // #region Properties
  data() {
    return {
      drag_status: 0,
    }
  },
  computed: {
    statusTitle() {
      const titles = [this.title, this.dragTitle, this.uploadTitle]

      return titles[this.drag_status]
    },
    allowInput() {
      // returns true if mode allow input file
      return (this.mode.trim().toLowerCase() === '' || this.mode.trim().toLowerCase() === 'all' || this.mode.trim().toLowerCase() === 'onlyinput')
    },
    allowDragAndDrop() {
      // returns true if mode allow input file
      return (this.mode.trim().toLowerCase() === '' || this.mode.trim().toLowerCase() === 'all' || this.mode.trim().toLowerCase() === 'onlydraganddrop')
    },
  },
  // #endregion Life Cycle

  // #region Watchers
  watch: {
  },
  // #endregion Properties

  methods: {
    // #region Drag & Drop Event Listners
    // eslint-disable-next-line no-unused-vars
    onDragOver() {
      if (!this.allowDragAndDrop) 
        return
      this.drag_status = 1
    },
    // eslint-disable-next-line no-unused-vars
    onDragLeave() {
      if (!this.allowDragAndDrop) 
        return
      this.drag_status = 0
    },
    onDrop(event: any) {
      if (!this.allowDragAndDrop) return
      this.drag_status = 2
      this.fileSelected(event.dataTransfer.files[0])    // gets only the 1st dropped file
    },
    // #endregion Drag & Drop Event Listners

    // #region Input[file] Event Listners
    onSelectFile(target: any) {
      // gets only the 1st dropped file
      this.fileSelected(target.files[0])    // gets only the 1st dropped file
    },
    // #endregion Input[file] Event Listners

    // #region Utils
    fileSelected(file: any) {
      // incoming file from drag&drop or selection
      /*
          envelopes a file object
          {
            data: <base64-file-data>,
            image: {
              width: <image.width>,
              height: <image.height>
            },
            lastModified: 1652440527861,
            name: "source-image-file-name.png",
            size: <size-in-bytes>,
            type: 'image/png'
          }
      */
      this.image_uploader_input.value = null      // alway resets input field
      
      // Checks if file is an image file
      if (file.type.toLowerCase().indexOf('image/') < 0) {
        // Wrong file type
        this.drag_status = 0
        this.$nextTick(function () {
          this.raise_onUnexcpectedFormat(file.type)
        })
        
      } else {
        // file is an image file
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this 
        const reader = new FileReader()

        reader.onload = (f: any): void => {
          file.data = f.target.result         // f.target.result contains the base64 encoding of the image
          file.file = file                    // attach original file-data
          const img = new Image()             // loads the image to get (and integrate) its size

          img.addEventListener('load', function () {
            file.image = {
              width: this.width,
              height: this.height,
            }
            self.raise_onUpload(file)         // ATTENTIONS: here we were using self instead of this. sends file meta-data and its data
            self.drag_status = 0              // ATTENTIONS: here we were using self instead of this. 
          })
          img.src = f.target.result.toString()
        }
        reader.readAsDataURL(file)
      }
    },
    // #endregion Utils

    // #region Events
    async raise_onUpload(data: any) {
      // checks if we have to resize to maxSize
      const cropperMaxSiz = 2000

      if ((data.image.width > cropperMaxSiz || data.image.height > cropperMaxSiz)) {
        data.data.resizeBase64(cropperMaxSiz).then((result: any) => {
          data.data = result.data
          data.image.width = result.width
          data.image.height = result.height
          this.$emit('onResize', data)        // notify auto-resize
          this.$emit('onUpload', data)
        })
      } else {
        this.$emit('onUpload', data)
      }
    },
    raise_onUnexcpectedFormat(fileType: any) {
      this.$emit('onUnexcpectedFormat', fileType)
    },
    // #region Events
  },
})
</script>

<style scoped>
.dragContainer {
  width:            v-bind(width);
  max-width:        v-bind(width);
  height:           200px;
  min-height:       200px;
  display:          flex;
  justify-content:  center;
  align-items:      center;
  text-align:       center;
  border-style:     dotted;
  border-width:     1px;
  padding:          30px;
  opacity:          .5;
  background:       transparent url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH5gULCyk0eo/e8QAABO9JREFUeNrtmu1v1EUQxz+9FuwDlFLbuqY8SEUSbOOgEh9i4A1gYvx7jRIhaoxvEDUTSiigpTwI3aQpLVIofbjzRfdCue7e79f+5nqn3PdVu3c3s/Pd2Z3ZmYU22mijjTZeX3Q0SrCI7AXeBroLiloGHqnqyn+GABH5CBg1Fvu3qv7e8gSIyDmgt0GO9UxVL1sK7DQ2/kPgzQZu2T3OuT7v/ayVwJKh8V3AoV04tw4FXSboMpzYcGL8T6BcYIGOR8ZHgIetRsCByNhdVb1R0LP2AEdrhvutCCgZEtAfGXtiIPdJTrKbTkBsUosGchdzkm2/BURkHzAYkpl6IXM9kfA0ioBuETmeEcUqIYmaV9Wn28oDRGQMOAHsKTDxsqp+YxRhvi7oravALVWdrusBItINnDFIXwEeGG6vB8CRIvkDMC4i7wI/q+ryljNARPqAC0bGV4BJQwImg8yi6AYuBFtfZoIiUgrGW6TGy8CPqrpqZb33vuKcuxfuFxah+6hz7i/vfaUq7HSdPbYAzId9VI+gMvBYVee2sbdLAKqamSgFt/1eRIaAgxlnQiW4/SAwkIh+p4ErHSLSC5yLfGkF+KXeCbqDw+ww4MLE9kb0zQOzqnrfUOc+4IuIPoDLHSIyDoxFGPxWVdcNJlACJiLZXBbuApN5vCPHHDqBryIePN3pnHsfeKPmg+uqOm+04mcTbpiFAeCEc+659/5JXrKdcx84544455a89y82nSFr4Q7xym24BPTFig9GRZFTBl58KsjKg89DuHTA2bDy9cJyXymWTRUtP4nIp8YVodEgMwuDNf8fzLCp0/I2WDV+IuJqm3EPuF+7xURkEDhcJ+EZEZEJVa2XXzxiow5ZNTozInUZGz8EHEt8PAv8ljrUAiHzInIN+Di4cS2OichsyjBVvSoix8KZNr3b9YBqPhHD9VgenjCiDPwa7iPjCR3f1fn9nWaVxI4mLk838xpfY8g0cDOW1wddLVcPeC92lVXVWzsVGH67mFNX8wgQkR6gJ/LRHwbiYzJ6gs6W8YC3ImNPVfWfooKDjKc5dTaNgIFESLJCTNaBViKgN3GLtEJMVl8rEVBKlKGssNqouVsRUGlwhCnl1Nk0Al5ExvYbErA/p86mERC7rg4bEjCcU2fTCJhLXF4Kyw8yRnLqbA4BdYonJw3En9ymzqalwjORsTER6S+w+v1sLdcB3LGatCUBqS7wmdBw2a7x1SZNDFMtR4CqrgG3EzrOi8jwNowfBs4n5nc76DKBaT1AVadEZDSSGXYAn4nIHHBDVRcShg+EPT+UUPFMVacs52xeEgN+Ar4k3rkdCluizEYP4NmmVHowwyPXg2xTlKwFBve8BKxl6B1io/53JPxdby5rwCVL128YAYGEFeCi0YVoAbjYqIeSnc6547VEOOdmvPeFukLe+4r3/p5z7nlIZLbbeC1vcKnXvPeF8/7wcrX2wdV6F7DE1icno1axNvT57ovIIeAdNtXqE3gMzKjqA+PFjj3hW2p4bzCxGgeAfbxsWK6wUUFabJC+ZG9wV7vDzUBmdzh86RPSNba87wNaCVnvAwC8ql6p5gFXg4vEosIAO+vutjLKweaNZCW0jx+Sbmv93/BDNax2bgpbq8bvcFoRy8H455tz9NjBYfFOsJWQfCfYkeMEzfNStFUPwsyXom200UYbbbzO+BdIObQJUJgEaQAAAABJRU5ErkJggg==') center center no-repeat
}
.dragContainer.no-border {
  border:none;
}
.input-file {
  background-color:red;
  opacity:          0;
  width:            96%;
  max-width:        96%;
  height:           53%;
  min-height:       53%;
  position:         absolute;
  cursor:           pointer;
}
.input-file:hover {
  background-color: var(--color-medium_gray);
}
</style>