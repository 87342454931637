<template>
  <v-hover v-slot="{ isHovering, props }">
    <v-card
      v-if="!onlyUploader"
      :data-cy="dataCy"
      :max-width="width"
      v-bind="props"
      elevation="0"
      style="overflow:hidden;"
      @click="display=true"
    >
      <img
        :src="logo"
        :style="height?`height:${height}px;width: auto;`:''"
        :class="`imglogo pa-3 ${imageClass}`"
      />
      <v-overlay
        v-if="enabled"
        :model-value="isHovering"
        contained
        scrim="#dedede"
        class="align-center justify-center"
      >
        <v-btn 
          variant="text"
          color="primary"
          size="x-large"
          class="align-self-center"
          :icon="$icons.edit"
        />
      </v-overlay>
    </v-card> 
  </v-hover>
  <v-dialog
    v-model="display"
    width="500"
    height="500"
    persistent
    class="dialog"
  >
    <v-card
      width="100%"
      height="100%"
    >
      <!-- icon + title + cancel button -->
      <v-card-title>
        <div class="d-inline mt-2">
          <v-icon class="mr-2">{{ $icons.add_image }}</v-icon>
          <span>{{ title }}</span>
        </div>
        <v-spacer/>
        <v-btn
          :disabled="isPosting || loadingStatus===0"
          variant="text"
          :icon="$icons.close"
          @click.stop="close()"
        />
      </v-card-title>

      <!-- body -->
      <v-card-text
        class="body ma-0 mt-3"
      >
        <FileUploader
          v-show="image.data===''"
          width="100%"
          height="100%"
          mode="all"
          title="Drag & drop your image here, or click to upload"
          drag-title="Leave your image here"
          upload-title="Uploading image..."
          @on-upload="onImageUpload($event)"
          @on-unexcpected-format="onUnexcpectedFormat($event)"
        />

        <!-- image loading -->
        <div
          v-show="loadingStatus===0"
          class="text-center"
        >
          <v-icon class="mr-1 spinner">{{ $icons.loader }}</v-icon>
          Loading Image, please wait...
        </div>

        <!-- image preview and cropper -->
        <cropper
          v-show="image.data!==''"
          ref="cropper"
          :src="image.data"
          :stencil-props="stencilProperties"
          :default-size="defaultSize"
          :stencil-component="stencilComponent"
          @change="change"
        />
      </v-card-text>
      <v-divider/>
      <!-- buttons -->
      <v-card-actions>
        <v-btn
          :disabled="isPosting || loadingStatus===0"
          color="secondary"
          @click="close()"
        >Cancel
        </v-btn>
        <v-spacer/>
        <v-btn
          data-cy="image-save"
          :disabled="isPosting || loadingStatus<=0"
          color="primary"
          @click="save()"
        >Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
// 
import { defineComponent } from 'vue'

// Cropper
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import 'vue-advanced-cropper/dist/theme.bubble.css'
import CircleStencil from '@/components/tools/CircleStencil.vue'

// Components
import FileUploader from '@/components/tools/FileUploader.vue'

export default defineComponent({
  name: 'ImageBase',
  components: {
    FileUploader,
    Cropper,
  },
  props: {
    modelValue: {
      type: String,
    },
    imageClass: {
      type: String,
    },
    title: {
      type: String,
    },
    enabled: {
      type: Boolean,
      default: true,
    },
    width: {
      type: Number,
      default: 320,
    },
    height: {
      type: Number,
    },
    isCircle: {
      type: Boolean,
      default: false,
    },
    aspectRatio: {
      type: Number,
      default: 1,
    },
    maxSize: {
      type: Number,
    },
    dataCy: {
      type: String,
    },
    onlyUploader: {
      type: Boolean,
      default: false,
    },
  },  
  emits: [
    'update:modelValue',
  ],
  setup() {
    return {
    }
  },

  // #region Properties
  data() {
    return {
      display: false,
      loadingStatus: -1,          // -1:none; 0:loading; 1:loaded
      error: '',
      image: {
        data: '',
        image: {
          width: 0,
          height: 0,
        },
        lastModified: 0,
        name: '',
        size: 0,
        type: '',
        gallery: '',
        tags: [],
      },
      isPosting: false,
      logo: this.modelValue,
      callback: null as any,
    }
  },
  computed: {
    stencilProperties() {
      const handlers = {
        eastNorth: true,
        north: true,
        westNorth: true,
        west: true,
        westSouth: true,
        south: true,
        eastSouth: true,
        east: true,
      }

      const ret = {
        handlers: handlers,
        aspectRatio: this.aspectRatio,
        movable: true,
        resizable: true,
      }

      return ret
    },
    stencilComponent() {
      if (this.isCircle)
        return CircleStencil
      else
        return undefined
    },
  },
  watch: { 
    modelValue: function (newVal)
    {
      this.logo = newVal
    },
    logo: function (newVal)
    {
      this.$emit('update:modelValue', newVal)
    },
  },
  // #endregion Properties

  // #region Methods
  methods: {
    show(callback?: any) {
      this.display = true
      this.callback = callback ?? null
    },
    defaultSize({ imageSize, visibleArea }: any) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height,
      }
    },
    
    save(): void {
      // Saves data
      this.isPosting = true

      // Saves data
      const { canvas } = (this.$refs.cropper as any).getResult()
      let source = canvas.toDataURL()

      if (this.maxSize && (canvas.width > this.maxSize || canvas.height > this.maxSize)) {
        source.resizeBase64(this.maxSize).then((result: any) => {
          source = result.data
        })
      }

      this.logo = source
      if (this.callback !== null) {
        // sends image to the callback function
        this.callback(this.logo)
      }
      this.close()
      this.isPosting = false
    },
    close(): void {
      this.image.data = ''
      this.display = false
    },
    onImageUpload(file: any): void {
      this.loadingStatus = 0

      this.image = {
        data: file.data,
        image: {
          width: file.image.width,
          height: file.image.height,
        },
        lastModified: file.lastModified,
        name: file.name,
        size: file.size,
        type: file.type,
        gallery: 'User',
        tags: [],
      }
    },
    onUnexcpectedFormat(fileType: string): void {
      this.$notification.error(`Wrong file format "${fileType}". Please, select an image file!`)
      this.loadingStatus = -1
    },
    change() {
      this.loadingStatus = 1
    },
  },
  // #endregion Methods
})
</script>

<style scoped>
.vue-advanced-cropper {
  overflow: hidden!important;
}
.imglogo
{
  width:100%;
}
</style>