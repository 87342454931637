/*
  ./store/message-box.ts
  message-box store
**/
import { defineStore } from 'pinia'

// #region Interfaces
export interface ButtonConfig {
  // Button object
  text: string
  key: string
  icon?: string
  color?: string
  button?: boolean
}
export interface LinkConfig {
  // link object
  text?: string
  url: string
  self?: boolean
}
export interface InputConfig {
  // input object
  label?: string
  placeholder?: string
  value?: string
  type?: string
}
export interface MessageConfig {
  // message object
  mode?: string
  title: string
  message: string
  subMessage?: string
  errorMessage?: string
  icon?: string
  buttons?: ButtonConfig []
  canCancel?: boolean
  large?: boolean
  details?: string[]
  showDetails?: boolean
  link?: LinkConfig
  input?: InputConfig
  dontShowAgain?: string
  detailsTitle?: string
}
export interface MessageCallback{
  (result: string): void;     // eslint-disable-line no-unused-vars
}
// #endregion Interfaces

// #region Store
export type RootState = {
  display: boolean
  message: MessageConfig,
  callback: MessageCallback
};

export const useMessageBoxStore = defineStore({
  id: 'message-box',
  state: () =>
    ({
      display: false,

      message: {
        mode: 'message-box',
        title: '',
        message: '',
        subMessage: '',
        errorMessage: '',
        icon: '',
        buttons: [],
        canCancel: false,
        large: false,
        details: [],
        showDetails: false,
        link: {
          text: '',
          url: '',
          self: false,
        },
        input: {
          label: '',
          placeholder: '',
          value: '',
        },
      },
      callback: () => undefined,    // eslint-disable-line no-unused-vars
    } as RootState),

  // actions
  actions: {
    show(payload: MessageConfig, callbackFunction?: MessageCallback) {

      // cancel/not previsous message box
      if (this.display) {
        if (this.message.canCancel) {
          this.display = false
        } else {
          return
        }
      }

      // normalizes payload
      payload = normalizeMessageProperties(payload)

      // checks don't show again previously given answer
      if (payload.dontShowAgain !== '') {
        const defValue = localStorage.getItem('message-box.' + payload.dontShowAgain)

        if (defValue !== null) {
          // we have a previous answer to the same message:
          // returns the previous answer without showing message
          if (callbackFunction) callbackFunction(defValue)

          return
        }
      }
      
      // show MessageBox
      setTimeout(() => {
        this.message = payload
        this.callback = (result: string): any => { // eslint-disable-line no-unused-vars
          if (callbackFunction) callbackFunction(result)

          return null
        }
        this.display = true
      }, 250)
    },
  },
})
// #endregion Store

// #region Utils
function normalizeMessageProperties(payload: MessageConfig): MessageConfig {
  // normalizes message properties
  const ret: MessageConfig = { ...payload }

  // sets empty properties to default values
  if (!ret.errorMessage) ret.errorMessage = ''
  if (!ret.subMessage) ret.subMessage = ''
  if (!ret.icon) ret.icon = ''
  if (!ret.buttons) ret.buttons = []
  if (ret.canCancel === undefined) ret.canCancel = false
  if (ret.large === undefined) ret.large = false
  if (!ret.details) ret.details = []
  if (ret.showDetails === undefined) ret.showDetails = false
  if (!ret.dontShowAgain) ret.dontShowAgain = ''

  // normalizes some variables
  ret.title = ret.title.trim()
  ret.message = ret.message.trim()
  ret.errorMessage = ret.errorMessage.trim()
  ret.subMessage = ret.subMessage.trim()
  ret.icon = normalizeIcon(ret.icon)
  ret.dontShowAgain = ret.dontShowAgain.trim().toLowerCase()

  // sets default buttons, if missing
  if (ret.buttons.length === 0) {
    ret.buttons.push({
      text: 'OK',
      key: 'ok',
      icon: '',
      button: true,
    })
  } else {
    // checks/normalizes button properties
    ret.buttons.forEach((btn) => {
      if (!btn.icon) btn.icon = ''
      if (btn.button === undefined) btn.button = true

      btn.text = btn.text.trim()
      btn.key = btn.key.trim().toLowerCase()
      btn.icon = normalizeIcon(btn.icon)
    })
  }

  // trims details, is specified
  if (ret.details.length > 0) {
    ret.details.forEach((d) => {
      // eslint-disable-next-line
      d = d.trim()
    })
  } else {
    ret.showDetails = false
  }

  // checks/normalizes link object, if specified
  if (ret.link) { 
    ret.link.url = ret.link.url.trim().toLowerCase()
    if (!ret.link.text) ret.link.text = ret.link.url          // url is the default text
    if (ret.link.self === undefined) ret.link.self = false    // links points _blank by default
  }

  // checks/normalizes input object, if specified
  if (ret.input) { 
    if (ret.input.label)
      ret.input.label = ret.input.label.trim()
    if (!ret.input.placeholder) ret.input.placeholder = ret.input.label  // lable is the default placeholder
    if (ret.input.value) ret.input.value = ret.input.value.trim()
    ret.errorMessage = ''           // no error on input-box-mode
  }

  return ret
}

function normalizeIcon(icon: string): string {
  // normalizes icon: allows some aliases
  switch (icon)
  {
    case 'ok':            // ok
      icon = 'mdi-check'
      break
    case 'check':
      icon = 'mdi-check'
      break
    case 'info':          // info
      icon = 'mdi-information-outline'
      break
    case 'information':
      icon = 'mdi-information-outline'
      break
    case 'exclamation':   // exclamation
      icon = 'mdi-exclamation'
      break
    case 'alert':         // alert
      icon = 'mdi-alert'
      break
    case 'warning':
      icon = 'mdi-alert'
      break
    case 'question':      // question
      icon = 'mdi-help-circle-outline'
      break
    case 'help':
      icon = 'mdi-help-circle-outline'
      break
    case 'message':       // message
      icon = 'mdi-message'
      break
    case 'delete':       // delete
      icon = 'mdi-delete'
      break
    case 'edit':        // edit
      icon = 'mdi-pencil-outline'
      break
    case 'copy':          // copy
      icon = 'mdi-content-copy'
      break
    case 'paste':        // paste
      icon = 'mdi-content-paste'
      break
    case 'cut':           // cut
      icon = 'mdi-content-cut'
      break
    case 'critical':      // critical
      icon = 'mdi-cancel'
      break
    case 'cancel':
      icon = 'mdi-cancel'
      break
    case 'error':
      icon = 'mdi-cancel'
      break
    case 'denied':
      icon = 'mdi-cancel'
      break
    case 'save':          // save
      icon = 'mdi-content-save'
      break
    case 'login':         // login, logout, signin, singout
      icon = 'mdi-login-variant'
      break
    case 'logout':
      icon = 'mdi-logout-variant'
      break
    case 'signin':
      icon = 'mdi-lock-open-check-outline'
      break
    case 'signout':
      icon = 'mdi-lock-open-check'
      break
    case 'start':       // play, stop, pause
      icon = 'mdi-play'
      break
    case 'play':
      icon = 'mdi-play'
      break
    case 'stop':
      icon = 'mdi-stop'
      break
    case 'pause':
      icon = 'mdi-pause'
      break
    case 'alarm':
      icon = 'mdi-alarm'
      break
    case '':            // null
      icon = ''
      break
    default:
  }

  return icon
}
// #endregion Utils